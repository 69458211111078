<template>
  <section class="lives_live">
    <div class="header">
      <img
        v-if="!siteLabel"
        class="super_button"
        src="@/assets/lives/super.png"
        @click="openHome()"
      />
      <div class="team home">
        <span class="name">{{ activeLive.HomeTeamStr }}</span>
        <img
          v-if="activeLive.HomeImage"
          class="logo"
          :src="`/timage/${activeLive.HomeImage}`"
          onerror="javascript:this.src='/img/no.png'"
        />
      </div>
      <img class="vs" src="@/assets/lives/vs.png" />
      <div class="team away">
        <img
          v-if="activeLive.AwayImage"
          class="logo"
          :src="`/timage/${activeLive.AwayImage}`"
          onerror="javascript:this.src='/img/no.png'"
        />
        <span class="name">{{ activeLive.AwayTeamStr }}</span>
      </div>
      <div class="header-buttons">
        <div
          v-if="canFullscreen"
          class="header-button fullscreen"
          :title="$t('lives.fullscreen')"
          @click="toggleFullscreen()"
        />
        <div
          v-else
          class="header-button fullwindow"
          :title="$t('lives.fullwindow')"
          @click="toggleFullwindow()"
        />
        <div class="header-button close" :title="$t('lives.close')" @click="hideLive()" />
      </div>
    </div>
    <iframe
      ref="videoIframeRef"
      class="video"
      :class="{ fullwindow: isFullwindow }"
      :src="activeLive.LiveLinkAry.slice(10)"
    />
    <div
      v-if="isFullwindow"
      class="iframe-close"
      :title="$t('lives.close')"
      @click.stop="toggleFullwindow()"
    />
    <div class="footer">
      <div class="catleague">
        <span
          class="icon"
          :style="{
            mask: 'url(' + require(`@/assets/lives/cats/${activeLive.CatID}.svg`) + ')',
          }"
        />
        <span class="cat">{{ activeLive.CatName }}</span>
        <span class="league">{{ activeLive.LeagueName }}</span>
      </div>
      <div class="time">{{ activeLive.ScheduleTimeStr.substr(0, 16) }}</div>
      <div class="bet" @click="openTable(activeLive)">{{ $t('lives.bet') }}</div>
    </div>
  </section>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex';
  import { isSupportFullscreen } from '@/config';

  export default {
    data() {
      return {
        canFullscreen: isSupportFullscreen,
        isFullwindow: false,
      };
    },
    computed: {
      ...mapState(['siteLabel']),
      ...mapState('Lives', ['cats', 'activeLive']),
    },
    methods: {
      ...mapMutations('Lives', ['hideLive']),
      ...mapActions('Lives', ['openHome', 'openTable']),
      toggleFullscreen() {
        const { $refs } = this;
        const { videoIframeRef } = $refs;
        if (videoIframeRef) {
          videoIframeRef.requestFullscreen();
        }
      },
      toggleFullwindow() {
        this.isFullwindow = !this.isFullwindow;
      },
    },
  };
</script>

<style lang="scss">
  .lives_live {
    display: flex;
    flex-flow: column nowrap;
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/lives/bg.jpg');
    background-size: cover;
    background-position: center;
    color: #868686;
    font-size: 14px;

    > .header {
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      position: relative;
      width: 100%;
      height: 72px;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
      background-color: #fff;

      > .super_button {
        position: absolute;
        width: 16vw;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        filter: brightness(0.9);

        &:hover {
          filter: brightness(1);
        }
      }

      > .team {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        > .name {
          display: inline-block;
          margin-left: 8px;
          margin-right: 8px;
          font-size: 16px;
        }

        > .logo {
          width: 32px;
        }

        &.home {
          right: calc(50% + 24px);
        }

        &.away {
          left: calc(50% + 24px);
        }
      }

      > .vs {
        position: absolute;
        width: 48px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      > .header-buttons {
        display: flex;
        flex-flow: row nowrap;
        position: absolute;
        width: fit-content;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);

        > .header-button {
          width: 24px;
          height: 24px;
          margin-left: 8px;
          background-color: #ccc;
          transition: transform 400ms ease;
          cursor: pointer;

          &.close {
            mask: url('~@/assets/live/close.svg');
          }

          &.fullscreen {
            mask: url('~@/assets/live/fullscreen.svg');
          }

          &.fullwindow {
            mask: url('~@/assets/live/maximize.svg');
          }

          &:hover,
          &:active {
            transform: scale(1.2);
          }
        }
      }
    }

    > iframe {
      flex-grow: 1;
      flex-shrink: 0;
      width: 90%;
      height: 200px;
      margin: 24px auto;
      border: 1px solid #000;
      background-color: #000;

      &.fullwindow {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        margin: 0;
      }
    }

    .iframe-close {
      position: fixed;
      width: 24px;
      height: 24px;
      right: 16px;
      top: 16px;
      z-index: 2;
      background-color: #ccc;
      transition: transform 400ms ease;
      cursor: pointer;
      mask: url('~@/assets/live/close.svg');

      &:hover,
      &:active {
        transform: scale(1.2);
      }
    }

    > .footer {
      flex-grow: 0;
      flex-shrink: 0;
      position: relative;
      width: 90%;
      height: 116px;
      margin: 0 auto 20px;
      padding: 16px;
      border-radius: 8px;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
      background-color: #fff;

      > .catleague {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        position: absolute;
        left: 16px;
        top: 20px;
        font-size: 15px;

        > .icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-left: 4px;
          margin-right: 4px;
          background-color: #0082ab;
        }

        > .cat {
          display: inline-block;
          color: #868686;
        }

        > .league {
          display: inline-block;
          color: #868686;

          &::before {
            margin-left: 4px;
            margin-right: 4px;
            content: '-';
          }
        }
      }

      > .time {
        position: absolute;
        left: 16px;
        top: 68%;
        transform: translateY(-50%);
        padding: 8px 16px;
        border: 1px solid #c7c7c7;
        border-radius: 8px;
        color: #868686;
        font-size: 16px;
        text-align: center;
      }

      > .bet {
        position: absolute;
        width: 200px;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);
        padding: 24px 0;
        border-radius: 8px;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
        background-image: linear-gradient(to right, #108df4, #00bea0);
        font-size: 20px;
        color: #fff;
        font-weight: bold;
        text-align: center;
        cursor: pointer;

        &:hover {
          box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.48);
        }
      }
    }
  }
</style>
